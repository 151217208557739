<template>
  <div
    class="d-flex mx-auto"
    :style="{
      width: width,
      height: height,
      minHeight: height,
      maxHeight: height,
      padding: padding,
      marginTop: marginTop,
    }"
  >
    <v-text-field
      class="ma-0 pa-0"
      v-bind="$attrs"
      v-model="inputText"
      placeholder="验证码"
      type="tel"
      maxlength="4"
      :rules="[rules.sms]"
      @focus="onFocus"
    >
      <div
        slot="prepend-inner"
        class="icon icon-1 d-flex align-center justify-center"
      >
        <v-icon class="primary--text" size="24px">icon-verification</v-icon>
      </div>
      <div slot="prepend-inner" class="line" small></div>
      <v-btn
        slot="append-outer"
        class="btn"
        :class="isSendSms ? 'btn-normal' : 'btn-selected'"
        width="90px"
        height="30px"
        elevation="0px"
        rounded
        @click="onSmsClick"
        >{{ btnText }}</v-btn
      >
    </v-text-field>
  </div>
</template>

<script>
import { isEmpty } from "@/views/common/web/isEmpty";
import { getUuid } from "@/views/common/web/uuid";
import { sendCode } from "@/api/login";
export default {
  inheritAttrs: false,
  name: "input-sms",
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "66px",
    },
    padding: {
      type: String,
      default: "0px 10px",
    },
    marginTop: {
      type: String,
      default: "16px",
    },
    phone: {
      type: String,
      default: "",
    }, //手机号
    text: {
      type: String,
      default: "",
    }, //默认文本
  },
  data() {
    return {
      inputText: this.text,
      isSendSms: false, //是否已经发送了验证码
      btnText: "获取验证码", //发送验证码按钮的文本
      countDownTime: 60, //倒计时时间
      internalId: -1,
      rules: {
        sms: (v) => {
          if (isEmpty(v)) {
            return true;
          }
          if (v.length > 4) {
            v = v.slice(0, 4);
          }
          const pattern = /^[0-9]*$/;
          if (pattern.test(v)) {
            this.$emit("onInput", v, true);
            return true;
          } else {
            this.$emit("onInput", v, false);
            return "验证码错误";
          }
        },
      },
    };
  },
  components: {},
  computed: {},
  methods: {
    onFocus(e) {
      this.$emit("onFocus", e);
    },
    onSmsClick() {
      this.$emit("onSmsClick");
    },
    countDown() {
      if (this.isSendSms) return;

      this.isSendSms = true;
      sendCode(this.phone, getUuid())
        .then((res) => {
          console.log(">>>>>发送短信成功");
          this.internalId = setInterval(() => {
            this.countDownTime--;
            if (this.countDownTime > 0) {
              this.btnText = this.countDownTime + "s后重发";
            } else {
              this.isSendSms = false;
              clearInterval(this.internalId);
              this.btnText = "发送验证码";
              this.countDownTime = 60;
            }
          }, 1000);
          this.showSnackbar({ msg: "验证码已发送" });
        })
        .catch(({ code, msg }) => {
          console.log(">>>>>发送短信失败");
          this.isSendSms = false;
          clearInterval(this.internalId);
          this.btnText = "发送验证码";
          this.countDownTime = 60;
          if (!msg) {
            return;
          }

          this.showSnackbar({ msg });
        });
    },
  },
  watch: {},
};
</script>

<style lang='scss' scoped>
.icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.icon-1 {
  margin-left: 10px;
}

.line {
  width: 1px;
  height: 12px;
  margin: 6px 10px 0px;
  background: "#5CB58F";
}

//v-text-field
::v-deep .v-input__slot {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  height: 42px;
  color: var(--v-primary-base);
  caret-color: var(--v-primary-base);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  min-height: inherit;
  position: relative;
  padding-top: 13px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}

//prepend-inner
.v-application--is-ltr .v-text-field ::v-deep .v-input__prepend-inner {
  margin-right: 0px;
  padding-right: 0px;
}

//append-inner
.v-application--is-ltr .v-text-field ::v-deep .v-input__append-inner {
  margin-left: auto;
  padding-left: 0px;
}

//prepend-inner append-inner
::v-deep .v-text-field .v-input__prepend-inner,
::v-deep .v-text-field .v-input__append-inner {
  -ms-flex-item-align: start;
  align-self: flex-start;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-top: 0px;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

//prepend-outer append-outer
::v-deep .v-input__append-outer,
.v-input__prepend-outer {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 1;
}

//v-text-field input位置 颜色 大小
::v-deep .v-text-field input {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  line-height: 20px;
  padding: 0 0;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: var(--v-primary-base);
  caret-color: var(--v-primary-base);
  margin-top: 2px;
}

//v-text-field input占位文字颜色
::v-deep .v-text-field input::-webkit-input-placeholder {
  color: var(--v-minor-base);
}

//v-text-field input占位文字颜色
::v-deep .v-text-field input:-moz-placeholder {
  color: var(--v-minor-base);
}

//v-text-field input占位文字颜色
::v-deep .v-text-field input::-moz-placeholder {
  color: var(--v-minor-base);
}

//v-text-field input占位文字颜色
::v-deep .v-text-field input:-ms-input-placeholder {
  color: var(--v-minor-base);
}

//v-text-field下方的线的位置、颜色
::v-deep .v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
  color: var(--v-minor-base);
}

//v-text-field下方的线的颜色
::v-deep
  .theme--light.v-text-field:not(.v-input--has-state):hover
  > .v-input__control
  > .v-input__slot:before {
  border-color: var(--v-minor-base);
}

//v-text-field错误文本
::v-deep .v-messages__message {
  margin: 8px 0px 0px 2px;
  height: 16px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: "#F20000";
  line-height: 16px;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

//按钮背景色
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: transparent !important;
}

.btn {
  border: 1px solid #4ca07c;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-top: 12px;
  margin-left: 9px;
}

.btn-normal {
  color: var(--v-minor-base);
}

.btn-selected {
  color: "#4CA07C";
}
</style>