<template>
  <pc-login-container
    centerTitle="短信快捷登录"
    topSecondTitle="学生登录"
    topThirdTitle="短信快捷"
    :showThirdTitle="true"
    @clickFirstTitle="clickFirstTitle"
    @clickSecondTitle="clickSecondTitle"
  >
    <v-col class="pa-0 ma-0">
      <input-phone
        :width="inputWidth"
        :height="inputHeight"
        :padding="inputPadding"
        :text="phone"
        type="tel"
        :maxLength="11"
        placeholder="手机号"
        @onInput="changePhone"
        icon="icon-phone"
        :error-messages="phoneErrorMsg"
      />

      <input-sms
        ref="inputCode"
        :width="inputWidth"
        :height="inputHeight"
        :padding="inputPadding"
        :marginTop="inputMarginTop"
        :phone="phone"
        @onInput="changeSms"
        :error-messages="smsErrorMsg"
        @onSmsClick="sendSms"
      />

      <submit-btn
        :width="btnWidth"
        :height="btnHeight"
        :marginTop="btnMarginTop"
        :loading="isShowBtnLoading"
        :disabled="!canClickBtn"
        @click="studentLoginSms"
        >登录</submit-btn
      >
    </v-col>
  </pc-login-container>
</template>

<script>
import PcMixins from "@/views/pc/_mixins/pc-mixins.js";
import { verifyPhone, loginSms } from "@/api/login";
import PcLoginContainer from "@/views/pc/components/pc-login-container";
import InputPhone from "@/components/input-phone";
import InputSms from "@/components/input-sms";
import SubmitBtn from "@/components/submit-btn";
import { isEmpty } from "@/views/common/web/isEmpty";
export default {
  name: "pc-login-student-sms",
  mixins: [PcMixins],
  props: {},
  data() {
    return {
      screenWidth: 0,
      screenHeight: 0,
      inputWidth: "480px",
      inputHeight: "80px",
      inputPadding: "0px 10px",
      inputMarginTop: "22px",
      btnWidth: "400px",
      btnHeight: "64px",
      btnMarginTop: "30px",

      phone: "", //手机号
      phoneErrorMsg: "", //手机号错误提示
      isPhone: false, //是不是正确的手机号格式
      isPhoneExit: true, //手机号是否已注册

      sms: "", //验证码
      smsErrorMsg: "", //验证码错误提示
      isSms: false, //是不是正确的验证码格式
      canSendSms: false, //能否发送验证码

      isShowBtnLoading: false, //按钮是否显示Loading
      canClickBtn: false, //能否点击按钮
    };
  },
  components: {
    PcLoginContainer,
    InputPhone,
    InputSms,
    SubmitBtn,
  },
  computed: {},
  methods: {
    //点击左上角
    clickFirstTitle() {
      this.$router.go(-2);
      // this.$router.replace({
      //   name: "pcLogin",
      // });
    },
    clickSecondTitle() {
      this.$router.go(-1);
      // this.$router.replace({
      //   name: "pcLoginStudent",
      // });
    },
    //判断按钮是否符合可以点击的条件
    isBtnCanClick() {
      return (
        this.phone && this.isPhone && this.isPhoneExit && this.sms && this.isSms
      );
    },
    //监听手机号改变
    changePhone(text) {
      this.phone = text;
      const pattern = /^[0-9]*$/;
      if (pattern.test(this.phone)) {
        //正则验证是否是手机号
        this.phoneErrorMsg = "";
        if (this.phone && this.phone.length == 11) {
          //达到11位时才去手机号查重
          this.isPhone = true;
          this.verifyPhone();
        } else {
          this.isPhone = false;
          this.canSendSms = false;
          this.canClickBtn = false;
        }
      } else {
        this.phoneErrorMsg = "手机号错误";
        this.isPhone = false;
        this.canSendSms = false;
        this.canClickBtn = false;
      }
    },
    //监听验证码改变
    changeSms(text, isOk) {
      this.sms = text;
      if (isOk) {
        if (this.sms && this.sms.length == 4) {
          this.isSms = true;
        } else {
          this.isSms = false;
        }
      } else {
        this.isSms = false;
      }
      if (this.isBtnCanClick()) {
        this.canClickBtn = true;
      } else {
        this.canClickBtn = false;
      }
    },
    //显示或隐藏密码
    showOrHidePassword() {
      this.isHidePassword = !this.isHidePassword;
      if (this.isHidePassword) {
        this.passwordType = "password";
      } else {
        this.passwordType = "text";
      }
    },
    //手机号查重
    verifyPhone() {
      verifyPhone(this.phone)
        .then((res) => {
          console.log(">>>>>", "手机号查重成功");
          if (res.data.exist == 1) {
            //已注册
            this.isPhoneExit = true;
            this.canSendSms = true;
            this.canClickBtn = this.isBtnCanClick();
          } else {
            //未注册
            this.phoneErrorMsg = "该手机号尚未注册，请先注册";
            this.isPhoneExit = false;
            this.canSendSms = false;
            this.canClickBtn = false;
          }
        })
        .catch(({ code, msg }) => {
          console.log(">>>>>", "手机号查重失败");
          if (!msg) {
            return;
          }

          this.showSnackbar({ msg });
        });
    },
    //获取验证码
    sendSms() {
      if (isEmpty(this.phone) || !this.isPhone) {
        this.phoneErrorMsg = "手机号错误";
      } else {
        if (!this.canSendSms) return;

        this.$refs.inputCode.countDown();
      }
    },
    //学生短信登录
    studentLoginSms() {
      this.$track.event({
        category: "student_signin",
        name: "sms",
        value: this.phone,
        needJwt: false,
      });
      this.isShowBtnLoading = true;
      if (this.canClickBtn) {
        loginSms(this.phone, this.sms)
          .then((res) => {
            this.isShowBtnLoading = false;
            if (!res) {
              console.log(">>>>>", "学生短信登录错误");
            } else {
              console.log(">>>>>", "学生短信登录成功");
              window.localStorage.setItem("jwt", res.data.jwt);
              this.$store.commit("auth/set", res.data.jwt);
              this.$track.event({
                category: "student_signin_success",
                name: "sms",
                value: this.$store.getters["auth/userId"],
              });
              this.$router.replace({
                name: "pcReaderIndex",
              });
            }
          })
          .catch(({ code, msg }) => {
            this.isShowBtnLoading = false;
            console.log(">>>>>", "学生短信登录失败");
            this.$track.event({
              category: "student_signin_fail",
              name: "sms",
              value: code,
              needJwt: false,
            });
            if (!msg) {
              return;
            }

            this.showSnackbar({ msg });
          });
      } else {
        this.isShowBtnLoading = false;
      }
    },
    //返回像素值
    getSize(size) {
      return Math.round(size) + "px";
    },
  },
  watch: {},
  created() {
    this.screenWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    this.screenHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    let x = this.screenWidth / 1920;
    let y = this.screenHeight / 1080;
    this.inputWidth = this.getSize(480 * x);
    this.inputHeight = this.getSize(80 * y);
    this.inputPadding = "0px" + this.getSize(10 * x);
    this.btnWidth = this.getSize(400 * x);
    this.btnHeight = this.getSize(64 * y);
    this.btnMarginTop = this.getSize(30 * y);
  },
};
</script>

<style lang="scss" scoped></style>
