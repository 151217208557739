var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex mx-auto",style:({
    width: _vm.width,
    height: _vm.height,
    minHeight: _vm.height,
    maxHeight: _vm.height,
    padding: _vm.padding,
    marginTop: _vm.marginTop,
  })},[_c('v-text-field',_vm._b({staticClass:"ma-0 pa-0",attrs:{"placeholder":"验证码","type":"tel","maxlength":"4","rules":[_vm.rules.sms]},on:{"focus":_vm.onFocus},model:{value:(_vm.inputText),callback:function ($$v) {_vm.inputText=$$v},expression:"inputText"}},'v-text-field',_vm.$attrs,false),[_c('div',{staticClass:"icon icon-1 d-flex align-center justify-center",attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_c('v-icon',{staticClass:"primary--text",attrs:{"size":"24px"}},[_vm._v("icon-verification")])],1),_c('div',{staticClass:"line",attrs:{"slot":"prepend-inner","small":""},slot:"prepend-inner"}),_c('v-btn',{staticClass:"btn",class:_vm.isSendSms ? 'btn-normal' : 'btn-selected',attrs:{"slot":"append-outer","width":"90px","height":"30px","elevation":"0px","rounded":""},on:{"click":_vm.onSmsClick},slot:"append-outer"},[_vm._v(_vm._s(_vm.btnText))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }